
//over-write bootstrap grey colors
$gray-dark:                       #282828 !default;
$gray:                            #2c2c2c !default;
$gray-light:                      #424242 !default;
$white:                           #ffffff !default;

//over-write bootstrap primary colors
$brand-primary:                   #00c0ef !default;
$brand-success:                   #00bf86 !default;
$brand-info:                      #668cff !default;
$brand-warning:                   #ffa000 !default;
$brand-danger:                    #ff8080 !default;

//our custom colors, will be used to generate buttons etc

/*
| mint colors related code;'
*/
$brand-mint:                      #0fb0c0 !default;

$btn-mint-color:                  #fff !default;
$btn-mint-bg:                     $brand-mint !default;
$btn-mint-border:                 $brand-mint !default;

/*
| Gray colors related code
*/

$brand-gray:                       #bac4c5 !default;

$btn-gray-color:                  #fff !default;
$btn-gray-bg:                     $brand-gray !default;
$btn-gray-border:                 $brand-gray !default;

//over-write fonts
$font-family-headings:  'Roboto', sans-serif;
$font-family-sans-serif: 'Source Sans Pro', sans-serif;

$headings-font-family: $font-family-headings;

// over-write alert colors, form feedback colors
$state-success-text:             $white;
$state-success-bg:               $brand-success;
$state-success-border:           $brand-success;

$state-info-text:                $white;
$state-info-bg:                  $brand-info;
$state-info-border:              $brand-info;

$state-warning-text:             $white;
$state-warning-bg:               $brand-warning;
$mark-bg:                        $state-warning-bg !default;
$state-warning-border:           $brand-warning;

$state-danger-text:              $white;
$state-danger-bg:                $brand-danger;
$state-danger-border:            $brand-danger !default;

//over-write progress-bars

// breadcrumb
$breadcrumb-bg:                  #f5f5fc !default;
$breadcrumb-divider-color:       #2180AC ;
$breadcrumb-divider:             "\f101";


//setting base font to 14, instead of default 1rem/16px
$font-size-base:                  14px !default;


//header font sizes

$font-size-h1:                   2.57rem !default;
$font-size-h2:                   2.14rem !default;
$font-size-h3:                   1.71rem !default;
$font-size-h4:                   1.28em !default;
$font-size-h5:                   1rem !default;
$font-size-h6:                   0.85rem !default;

$headings-color:                #525252 !default;

$headings-font-weight:           500 !default;

$input-height :                  2.5rem;

//over-write link color
$link-color:                     #525252 !default;

//link

$link-hover-decoration:          none !default;
//navbar
$navbar-border-radius:           0 !default;

//body
$body-bg:                        #e9e9e9 !default;

//line height
$line-height-base:                1.8 !default;

//overwrite placeholder color

$input-color-placeholder:        #999 !default;